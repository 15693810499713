<template>
<!-- 网站建设页面 -->
<div class="index">
  <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />
  
    <div class="build"></div>
    <ServerTop title="企业官网开发设计" wera="企业营销型网站开发（PC网站+移动端网站）" jiage="16800" qi='true'/>
    
    <div class="importance">
      <div class="header">
          <div class="lan"></div>
          <p>服务详情</p>
        </div>
      <div class="title">
            <img src="../imger/AR/title.png" alt="网站开发" class="titletubiaoleft">
            <p>网站的重要性</p>
            <img src="../imger/AR/title.png" alt="网站开发" class="titletubiaoright">
          </div>
      <div class="word">
        <p>互联网时代，网络的宣传与展示对企业发展其绝对性因素，企业通过建设官方网站，能让用户通过网站间</p>
        <p>接的了解公司的品牌形象，产品信息，荣誉资质等综合实力，而网站建设中的</p> 
        <p>最主要的就是建设营销型网站，并实现pc端和移动端的完美配置。</p>
      </div>
      <div class="bigbox">
        <div class="box">
          <img src="../imger/build/circle1.png" alt="网站开发" class="circle1" />
          <p class="boxword">网站更具优化型和抓取度，每个页面要有相对应的标题、关键词、描述。</p>
          <img src="../imger/build/icon1.png" alt="网站开发" class="icon1" />
        </div>
        <div class="box">
          <img src="../imger/build/circle2.png" alt="网站开发" class="circle1" />
          <p class="boxword">功能全面，方便访客寻找自己需要浏览的内容，第一时间满足客户的需要，促成成交。</p>
          <img src="../imger/build/icon2.png" alt="网站开发" class="icon1" />
        </div>
        <div class="box">
          <img src="../imger/build/circle3.png" alt="网站开发" class="circle1" />
          <p class="boxword">可以自主实现在线支付和购买功能，也可以借助第三方平台，更好的方便网站营销，促进客户消费。</p>
          <img src="../imger/build/icon3.png" alt="网站开发" class="icon1" />
        </div>
      </div>
    </div>

    <div class="upgrade">
      <div class="title">
            <img src="../imger/AR/title.png" alt="网站开发" class="titletubiaoleft">
            <p>传统网站都需升级</p>
            <img src="../imger/AR/title.png" alt="网站开发" class="titletubiaoright">
          </div>
      <div class="word">
        <p>传统网站基于IPv4网络建设，存在他国网络攻击隐患。新建网站基于IPv6新一代互联网技术，同时兼容</p>
        <p>IPv4网络，助力企业在全球化时代构建安全网络空间。</p>
        <p><span class="tbword">PC站+手机站+微信网站+小程序+APP</span><span>，助力企业建设多端合一官方入口。</span></p>
      </div>
      <img src="../imger/build/computer.png" alt="网站搭建-专业网站建设公司" class="computer">
    </div>

    <div class="process">
     <ServerFoot :data="serverData" :show="false"/>
    </div>

  <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />  
</div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import Sidebar from '../components/sidebar.vue'
import Eject from '../components/eject.vue'
import Drawer from '../components/drawer.vue'
import ColumnSearch from '../components/columnSearch.vue'
import TradHeader from '../components/trademarkClassification/tradHeader.vue'
import ServerTop from '../components/serverTop.vue'
import ServerFoot from '../components/serverFoot.vue'
import Title from '../components/trademarkRegistration/title.vue'

export default {
  components:{
    Sidebar,Footer,Header,Drawer,Eject,ColumnSearch,
    TradHeader,Title,ServerTop,ServerFoot
  },
  data(){
    return {
      serverData:[
        {
          title:'需求对接',
          content:'充分沟通需求，确定网站结构及页面设计思路'
        },
        {
          title:'签单打款',
          content:'约定付款方式签订合同，支付定金开始执行'
        },
        {
          title:'策划设计',
          content:'组建项目群，梳理页面文案设计页面效果图'
        },
        {
          title:'设计定稿',
          content:'提供页面设计初稿，客户确认后输出最终效果图'
        },
        {
          title:'技术开发',
          content:'根据效果图进行技术开发，还原设计效果'
        },
        {
          title:'网站测试',
          content:'对网站功能、体验及性能进行专业测试 '
        },
        {
          title:'网站上线',
          content:'对网站各页面TDK进行布局后正式上线 '
        },
      ]
    }
  },
  methods:{
    
  }
  
}
</script>

<style scoped lang="scss">
.index{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .build {
    width:100%;
    height: 28.75rem /* 460px -> 28.75rem */;
    background-image: url("../imger/build/build.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .header{
        width: 75rem /* 1200px -> 75rem */;
        height: 3.125rem /* 50px -> 3.125rem */;
        padding: 0.625rem /* 10px -> 0.625rem */;
        box-sizing: border-box;
        font-size: 1.25rem /* 20px -> 1.25rem */;
        color: #333333;
        background-color: #F7F7F7;
        display: flex;
        align-items: center;
        .lan {
          width: 0.3125rem /* 5px -> 0.3125rem */;
          height: 1.6875rem /* 27px -> 1.6875rem */;
          margin-right: 0.875rem /* 14px -> 0.875rem */;
          background-color: #4D89F1;
        }
      }
      .title{
          width: 100%;
          font-size: 1.5rem /* 24px -> 1.5rem */;
          color: #333333;
          display: flex;
          justify-content: center;
          align-items: center;
          .titletubiaoleft {
            width: 1.625rem /* 26px -> 1.625rem */;
            height: 100%;
            margin-right: 1.25rem /* 20px -> 1.25rem */;
          }
          .titletubiaoright {
            width: 1.625rem /* 26px -> 1.625rem */;
            height: 100%;
            margin-left: 1.25rem /* 20px -> 1.25rem */;
          }
        }
  .importance {
      width: 100%;
      height: 41.125rem /* 658px -> 41.125rem */;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .word{
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #666666;
        line-height: 2.6875rem /* 43px -> 2.6875rem */;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .bigbox {
        width: 74.8125rem /* 1197px -> 74.8125rem */;
        height: 16.625rem /* 266px -> 16.625rem */;
        display: flex;
        justify-content: space-between;
        .box {
          width: 51.625rem /* 826px -> 51.625rem */;
          height: 16.625rem /* 266px -> 16.625rem */;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          font-size: 1.125rem /* 18px -> 1.125rem */;
          color: #666666;
          .circle1{
            width:2.5rem /* 40px -> 2.5rem */;
            height: 2.5rem /* 40px -> 2.5rem */;
          }
          .icon1{
            width: 10.6875rem /* 171px -> 10.6875rem */;
            height: 5.875rem /* 94px -> 5.875rem */;
          }
          .boxword {
            width: 19rem /* 304px -> 19rem */;
          }
        }
      }
      
    }
    .upgrade {
      width: 100%;
      height: 47.25rem /* 756px -> 47.25rem */;
      background-color: #E5EFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .word{
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #666666;
        display: flex;
        flex-direction: column;
        align-items: center;
        .tbword{
          font-size: 1.5rem /* 24px -> 1.5rem */;
          color: #333333;
        }
      }
      .computer {
        width: 40.625rem /* 650px -> 40.625rem */;
        height: 23.3125rem /* 373px -> 23.3125rem */;
      }
    }
    .process{
      width: 75rem /* 1200px -> 75rem */;
      height: 29.0625rem /* 465px -> 29.0625rem */;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

    }
}
</style>